/* eslint-disable @typescript-eslint/no-unused-vars */
import { Error, Success } from "."
import { ButtonLoader, Empty } from ".."
import { useAuth } from "../../context/authContext"
import { IUser } from "../../db/models"
import { updateUser } from "../user/user.service"
import * as Sentry from "@sentry/nextjs"
import React, { useState } from "react"
import { useForm } from "react-hook-form"

interface FormData {
  email: string
  password: string
  firstName: string
  lastName: string
  phone: string
}

export function UserTab() {
  const [pageLoading, setPageLoading] = useState(true)
  const [loading, setLoading] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [emailChanged, setEmailChanged] = useState("")
  const { register, handleSubmit } = useForm<FormData>()

  const { user, checkSession } = useAuth()

  React.useEffect(() => {
    if (user) {
      setPageLoading(false)
    }
  }, [user])

  const onSubmit = async ({
    email = null,
    firstName = null,
    lastName = null,
    phone = null,
  }: FormData) => {
    setLoading(true)

    const requestBody: Partial<IUser> = {
      ...(firstName != user.firstName && { firstName }),
      ...(lastName != user.lastName && { lastName }),
    }

    try {
      await updateUser(user.id, requestBody)
      await checkSession()
    } catch (error) {
      setErrorMessage(error.message)
      Sentry.captureException(error.message)
      setLoading(false)
      return
    }

    setLoading(false)
    setShowSuccess(true)

    // if (data.user) {
    //   if (email !== data.user.email) {
    //     setEmailChanged(email)
    //   }
    //   setShowSuccess(true)
    // }
  }

  if (pageLoading) {
    return <Empty loading={pageLoading} message="" />
  }
  return (
    <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
      <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
        <div>
          <h2 className="text-xl font-medium text-alt">Personal Information</h2>
        </div>
        <div className="mt-5 md:mt-0 md:col-span-2">
          {errorMessage && <Error message={errorMessage} />}
          {showSuccess && (
            <Success>
              {emailChanged && (
                <p>Email has been successfully changed to {emailChanged}</p>
              )}
            </Success>
          )}
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mt-6 grid grid-cols-2 md:grid-cols-4 gap-6">
              <div className="col-span-2">
                <label
                  htmlFor="firstName"
                  className="block text-sm font-medium text-gray-700"
                >
                  First name
                </label>
                <input
                  defaultValue={user.firstName}
                  type="text"
                  name="firstName"
                  id="firstName"
                  className="mt-1 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md "
                  {...register("firstName")}
                />
              </div>

              <div className="col-span-2">
                <label
                  htmlFor="lastName"
                  className="block text-sm font-medium text-gray-700"
                >
                  Last name
                </label>
                <input
                  defaultValue={user.lastName}
                  type="text"
                  name="lastName"
                  id="lastName"
                  className="mt-1 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md "
                  {...register("lastName")}
                />
              </div>

              <div className="col-span-2">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email address
                </label>
                <input
                  disabled
                  defaultValue={user.email}
                  type="email"
                  name="email"
                  id="email"
                  autoComplete="email"
                  className="mt-1 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md 
                  disabled:opacity-50"
                  {...register("email")}
                />
              </div>

              {/* <div className="col-span-2">
                <label
                  htmlFor="phone"
                  className="block text-sm font-medium text-gray-700"
                >
                  Phone number
                </label>
                <input
                  defaultValue={user.phone}
                  type="tel"
                  name="phone"
                  id="phone"
                  autoComplete="phone"
                  className="mt-1 focus:ring-primary focus:border-primary block w-full shadow-sm sm:text-sm border-gray-300 rounded-md "
                  {...register("phone")}
                />
              </div> */}
              <div className="col-span-2 md:col-span-4 flex justify-end">
                <button type="submit" className="btn-primary">
                  <ButtonLoader loading={loading} loadingText="Saving">
                    Save
                  </ButtonLoader>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

import React from "react"

interface Action {
  content: string
  onAction: () => void
}
export interface Props {
  loading?: boolean
  action?: Action
  message: string
}

export function Empty({ loading, action, message }: Props) {
  if (loading) {
    return (
      <div className="animate-pulse w-full bg-white p-8 mt-2 text-center border border-gray-200 rounded-lg shadow-sm">
        <div className="my-4 h-10 bg-slate-200 rounded col-span-2"></div>
        <div className="my-4 h-4 bg-slate-200 rounded col-span-2"></div>
        <div className="my-4 h-4 bg-slate-200 rounded col-span-2"></div>
      </div>
    )
  }

  return (
    <div className="w-full bg-white p-8 mt-2 text-center border border-gray-200 rounded-lg shadow-sm">
      <p className="text-lg text-gray-500">{message}</p>
      {action && (
        <button
          onClick={action.onAction}
          type="button"
          className="btn-primary mt-4"
        >
          {action.content}
        </button>
      )}
    </div>
  )
}

import { useLicenseContext } from "../context/licenseContext"
import { ILicense } from "../db/models"
import { Listbox, Transition } from "@headlessui/react"
import { ChevronDownIcon, KeyIcon } from "@heroicons/react/outline"
import { Fragment } from "react"

export function LicenseSelector({
  placement = "left",
}: {
  placement?: "left" | "right"
}) {
  const { licenses, selectedLicense, setSelectedLicense } = useLicenseContext()

  const handleSelect = (selection: ILicense) => {
    setSelectedLicense(selection)
    // router.replace(router.asPath)
  }

  return (
    <div className="flex items-end text-gray-500 text-sm font-medium">
      {!selectedLicense ? (
        "..."
      ) : (
        <Listbox value={selectedLicense} onChange={handleSelect}>
          <div className="relative">
            <Listbox.Button className="relative cursor-pointer hover:text-alt px-1 flex items-center gap-1 font-semibold text-alt group">
              {process.env.NODE_ENV === "development" ? (
                <span className="uppercase font-semibold tracking-wide inline-flex items-center rounded-full bg-sky-50 px-2 py-1 text-[10px] text-sky-800 ring-1 ring-inset ring-sky-600/20">
                  <KeyIcon
                    className="flex-shrink-0 mr-1.5 h-4 w-4 text-sky-800"
                    aria-hidden="true"
                  />
                  UAT
                </span>
              ) : process.env.NEXT_PUBLIC_VERCEL_ENV !== "production" ? (
                <span className="uppercase font-semibold tracking-wide inline-flex items-center rounded-full bg-yellow-50 px-2 py-1 text-[10px] text-yellow-800 ring-1 ring-inset ring-yellow-600/20">
                  <KeyIcon
                    className="flex-shrink-0 mr-1.5 h-4 w-4 text-yellow-800"
                    aria-hidden="true"
                  />
                  STAGING
                </span>
              ) : (
                <span className="uppercase font-semibold tracking-wide inline-flex items-center rounded-full bg-green-50 px-2 py-1 text-[10px] text-green-800 ring-1 ring-inset ring-green-600/20">
                  <KeyIcon
                    className="flex-shrink-0 mr-1.5 h-4 w-4 text-green-800"
                    aria-hidden="true"
                  />
                  PRODUCTION
                </span>
              )}
              <span className="ml-2">{selectedLicense?.name}</span>

              <span className="pointer-events-none">
                <ChevronDownIcon
                  className="h-4 w-4 text-gray-400 group-hover:text-gray-500"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Listbox.Options
                className={`z-50 absolute mt-1 max-h-60 w-auto min-w-[120px] overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm ${
                  placement === "right" ? "right-0" : "left-0"
                }`}
              >
                {licenses &&
                  licenses.map((license) => (
                    <Listbox.Option
                      key={license.id}
                      className="relative cursor-default select-none py-2 px-4 text-alt hover:bg-gray-100"
                      value={license}
                    >
                      {({ selected }) => (
                        <span
                          className={`block truncate ${
                            selected ? "font-semibold" : "font-normal"
                          }`}
                        >
                          {license.name}{" "}
                          <span className="ml-2 text-gray-400 text-xs">
                            {license.id}
                          </span>
                        </span>
                      )}
                    </Listbox.Option>
                  ))}
              </Listbox.Options>
            </Transition>
          </div>
        </Listbox>
      )}
    </div>
  )
}

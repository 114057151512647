import fetchCrudService from "../../service/fetchCrudService"

async function submitPartnerSupportForm(
  firstName: string,
  lastName: string,
  partnerName: string,
  email: string,
  phoneNumber: string,
  message: string
) {
  const res = await fetchCrudService(
    "POST",
    {
      firstName,
      lastName,
      partnerName,
      email,
      phoneNumber,
      message,
    },
    "forms/submitPartnerSupportForm"
  )

  return res
}

export { submitPartnerSupportForm }

import React from 'react';

export interface Props {
  rows?: number;
}

export function TableSkeleton({ rows = 2 }: Props) {
  return (
    <div className="-mx-4 mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
      <table className="min-w-full divide-y divide-gray-300">
        <tbody className="divide-y divide-gray-200 bg-white">
          {[...Array(rows)].map((_, index) => (
            <tr key={index}>
              <td className="w-full max-w-0 py-4 pl-4 pr-3 md:w-auto md:max-w-none sm:pl-6">
                <div className="h-6 bg-gray-200 rounded-md animate-pulse w-32 md:w-full" />
                <dl className="lg:hidden">
                  <dd className="mt-1 flex items-center gap-2">
                    <div className="inline-flex w-16 h-4 rounded-md bg-gray-200" />
                  </dd>
                  <dd className="mt-1 flex items-center gap-2">
                    <div className="inline-flex w-16 h-4 rounded-md bg-gray-200" />
                  </dd>
                </dl>
              </td>
              <td className="hidden px-3 py-4 lg:table-cell">
                <div className="h-6 bg-gray-200 rounded-md animate-pulse" />
              </td>
              <td className="hidden px-3 py-4 lg:table-cell">
                <div className="h-6 bg-gray-200 rounded-md animate-pulse" />
              </td>
              <td className="hidden py-4 pl-3 pr-4 sm:pr-6 lg:table-cell">
                <div className="h-6 bg-gray-200 rounded-md animate-pulse" />
              </td>
              <td className="py-4 pl-3 w-auto">
                <div className="h-6 bg-gray-200 rounded-md animate-pulse w-20 float-right" />
              </td>
              <td className="py-4 pl-3 pr-4 sm:pr-6 w-auto">
                <div className="h-6 bg-gray-200 rounded-md animate-pulse w-20 float-right" />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

import { CheckCircleIcon } from "@heroicons/react/outline"
import React from "react"

export interface Props {
  children?: React.ReactNode
  message?: string
}

export function Success({
  children,
  message = "Successfully updated.",
}: Props) {
  return (
    <div className="rounded-md bg-green-50 p-4 mb-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <CheckCircleIcon
            className="h-5 w-5 text-green-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3 text-sm text-green-800">
          <p className="font-medium ">{message}</p>
          {children}
        </div>
      </div>
    </div>
  )
}

import { useAuth } from "../../context/authContext"
import { Disclosure, Transition } from "@headlessui/react"
import { ChevronUpIcon } from "@heroicons/react/outline"
import Link from "next/link"
import React from "react"

interface NavItem {
  href: string
  name: string
}

export interface Props {
  name: string
  navigation: NavItem[]
}

export function ProfileDisclosure({ name, navigation }: Props) {
  const { logout } = useAuth()
  return (
    <Disclosure>
      {({ open }) => (
        <>
          <Disclosure.Button className="flex-shrink-0 w-full group block py-4 border-t border-gray-700">
            <div className="flex items-center justify-between px-4">
              <div className="flex-shrink-1 text-sm text-gray-300 group-hover:text-white truncate">
                {name}
              </div>
              <div className="ml-2">
                <ChevronUpIcon
                  className={`${
                    open ? "rotate-180 transform" : ""
                  } h-5 w-5 text-gray-300 group-hover:text-white`}
                />
              </div>
            </div>
          </Disclosure.Button>
          <Transition
            enter="transition duration-150 ease-in-out"
            enterFrom="transform min-h-0 opacity-0"
            enterTo="transform min-h-max opacity-100"
            leave="transition ease-in-out duration-50"
            leaveFrom="transform min-h-max opacity-50"
            leaveTo="transform min-h-0 opacity-0"
          >
            <Disclosure.Panel className="static bg-alt left-0 bottom-full z-10 w-full space-y-1 py-1 px-2 focus:outline-none">
              {navigation.map((item) => (
                <Link
                  key={item.name}
                  href={item.href}
                  className="block px-4 py-2 rounded-md text-sm text-gray-300 font-medium hover:text-white"
                >
                  {item.name}
                </Link>
              ))}
              <a
                role={"button"}
                className="w-full block text-left px-4 py-2 rounded-md text-sm text-gray-300 font-medium hover:text-white"
                onClick={logout}
              >
                Sign out
              </a>
            </Disclosure.Panel>
          </Transition>
        </>
      )}
    </Disclosure>
  )
}

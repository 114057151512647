/* eslint-disable @typescript-eslint/no-unused-vars */
import { adminUserIds } from "../../constants/navigation"
import { Auth0User } from "../../context/authContext"
import { IUser } from "../../db/models"
import { getUserById } from "../user/user.service"
import { UserIcon } from "@heroicons/react/outline"
import { useEffect, useState } from "react"
import { useCookies } from "react-cookie"

export interface Props {
  superUser: Auth0User
  user: Auth0User
  impersonatedUserId: string
}

export function ImpersonateUserBanner({
  superUser,
  user,
  impersonatedUserId,
}: Props) {
  const [isShowing, setIsShowing] = useState(false)
  const [cookies, setCookie, removeCookie] = useCookies(["impersonatedUser"])
  const [impersonatedUser, setImpersonatedUser] = useState<IUser | null>(null)

  useEffect(() => {
    async function init() {
      if (impersonatedUserId && adminUserIds.includes(superUser.id)) {
        const userToImpersonate = await getUserById(cookies.impersonatedUser)
        if (userToImpersonate) {
          setImpersonatedUser(userToImpersonate)
          setIsShowing(true)
        }
      }
    }

    init()
  }, [cookies.impersonatedUser, impersonatedUserId, superUser])

  return isShowing ? (
    <div className="bg-gray-500">
      <div className="mx-auto max-w-7xl py-1 px-3 sm:px-6 lg:px-8">
        <div className="flex flex-wrap items-center justify-between">
          <div className="flex w-0 flex-1 items-center">
            <span className="flex rounded-lg bg-gray-800 p-2">
              <UserIcon className="h-4 w-4 text-white" aria-hidden="true" />
            </span>
            <p className="ml-3 font-medium text-white text-sm">
              {impersonatedUser.firstName} {impersonatedUser.lastName} (
              {impersonatedUser.email})
            </p>
          </div>
          <div className="order-3 mt-2 w-full flex-shrink-0 sm:order-2 sm:mt-0 sm:w-auto">
            <a
              href="#"
              className="flex items-center justify-center rounded-md border border-transparent bg-white px-4 py-[2px] text-sm font-medium text-gray-600 shadow-sm hover:bg-gray-50"
              onClick={() => {
                removeCookie("impersonatedUser")
                window.location.href = "/"
              }}
            >
              End
            </a>
          </div>
          <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-3">
            <button
              type="button"
              className="-mr-1 flex rounded-md p-2 hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-white sm:-mr-2"
            >
              <span className="sr-only">Dismiss</span>
              {/* <XIcon className="h-6 w-6 text-white" aria-hidden="true" /> */}
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : null
}

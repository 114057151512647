import React, { Fragment, useRef } from 'react';

import { Dialog, Transition } from '@headlessui/react';

import { classNames } from '../../utilities';

export interface PrimaryAction {
  content: string;
  disabled?: boolean;
  onAction: () => void;
  hideActions?: boolean;
  variant?: 'primary' | 'danger';
}
export interface Props {
  isOpen: boolean;
  title?: string;
  children: React.ReactNode;
  closeModal: () => void;
  primaryAction?: PrimaryAction;
  hideActions?: boolean;
}

export function Modal({
  isOpen,
  closeModal,
  title,
  children,
  primaryAction,
  hideActions
}: Props) {
  const submitButtonRef = useRef();
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        onClose={closeModal}
        initialFocus={submitButtonRef}
        static
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-md bg-white p-6 text-left align-middle shadow-xl transition-all">
                {title && (
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-alt"
                  >
                    {title}
                  </Dialog.Title>
                )}
                <div className="mt-4">{children}</div>
                {!hideActions && (
                  <div className="mt-6 flex justify-end space-x-3">
                    <button
                      type="button"
                      className="btn-secondary"
                      onClick={closeModal}
                    >
                      Cancel
                    </button>
                    <button
                      ref={submitButtonRef}
                      type="button"
                      className={classNames(
                        primaryAction.disabled && 'btn-disabled',
                        primaryAction.variant === 'danger'
                          ? 'btn bg-red-500 hover:bg-red-600 shadow-red-500/50 border border-red-500 focus:ring-red-500 text-white'
                          : 'btn-primary'
                      )}
                      onClick={primaryAction.onAction}
                    >
                      {primaryAction.content}
                    </button>
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

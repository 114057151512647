import React from 'react';

import { Spinner } from '..';

export interface Props {
  loading: boolean;
  loadingText: string;
  children: React.ReactNode;
}

export function ButtonLoader({ loading, loadingText, children }: Props) {
  return loading ? (
    <>
      <Spinner />
      <span>{loadingText}...</span>
    </>
  ) : (
    <>{children}</>
  );
}

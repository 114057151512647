import { classNames } from "../../utilities"
import { LicenseSelector } from "../LicenseSelector"
import { HomeIcon } from "@heroicons/react/solid"
import Link from "next/link"
import React from "react"

export interface Breadcrumb {
  title: string
  href: string
  current?: boolean
}

export interface PageAction {
  content: string
  action?: () => void
  url?: string
  disabled?: boolean
}
export interface Props {
  title: React.ReactNode
  children: React.ReactNode
  breadcrumbs?: Breadcrumb[]
  pageAction?: PageAction
}

export function PageWrapper({
  title,
  children,
  breadcrumbs,
  pageAction,
}: Props) {
  const Action = () =>
    pageAction.url ? (
      <Link
        href={pageAction.url}
        className={`btn-primary ${
          pageAction.disabled
            ? "cursor-default bg-gray-400 hover:bg-gray-400"
            : null
        }`}
      >
        {pageAction.content}
      </Link>
    ) : (
      <button
        onClick={pageAction.action}
        className={`btn-primary ${
          pageAction.disabled
            ? "cursor-default bg-gray-400 hover:bg-gray-400"
            : null
        }`}
      >
        {pageAction.content}
      </button>
    )

  return (
    <div className="relative">
      {breadcrumbs?.length && (
        <div className="flex items-center justify-between bg-white border-b border-gray-200 px-4 sm:px-6 lg:px-8">
          <nav className="flex" aria-label="Breadcrumb">
            <ol role="list" className="max-w-7xl w-full mx-auto flex space-x-4">
              <li className="flex">
                <div className="flex items-center">
                  <Link href="/" className="text-gray-400 hover:text-alt">
                    <HomeIcon
                      className="flex-shrink-0 h-5 w-5"
                      aria-hidden="true"
                    />
                    <span className="sr-only">Home</span>
                  </Link>
                </div>
              </li>
              {breadcrumbs.map(({ title, href, current }) => (
                <li key={title} className="h-10 mr-2">
                  <div className="hidden sm:flex items-center h-full">
                    <svg
                      className="flex-shrink-0 w-6 h-full text-gray-200"
                      viewBox="0 0 24 44"
                      preserveAspectRatio="none"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                    >
                      <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                    </svg>
                    <a
                      href={href}
                      className={classNames(
                        "ml-4 text-sm font-medium truncate",
                        current ? "text-alt" : "text-gray-400 hover:text-alt"
                      )}
                      aria-current={current ? "page" : undefined}
                    >
                      {title}
                    </a>
                  </div>
                </li>
              ))}
            </ol>
          </nav>
          <LicenseSelector placement="right" />
        </div>
      )}

      <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 pb-8">
        <div className="my-6 sm:flex items-center justify-between">
          <div className="flex-1 min-w-0">
            <h1 className="text-2xl font-bold leading-7 text-alt sm:text-3xl sm:truncate mb-3 sm:mb-0">
              {title}
            </h1>
          </div>
          {pageAction && <Action />}
        </div>
        <div>{children}</div>
      </div>
    </div>
  )
}

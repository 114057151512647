import Image from "next/image"
import React from "react"

export function Logo({
  width = 169,
  height = 28,
}: {
  width?: number
  height?: number
}) {
  return (
    <div className="relative mx-auto" style={{ width, height }}>
      <Image
        src="https://iamyqpnnibrsxhgaflmh.supabase.co/storage/v1/object/public/images-public/svg/logo.svg"
        // width={169}
        // height={28}
        alt="CoverDash Logo"
        priority
        layout="fill"
      />
    </div>
  )
}

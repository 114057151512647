import { ProfileDisclosure, ProfilePicture, Logo as SiteLogo } from ".."
import {
  adminUserIds,
  navigation,
  userNavigation,
} from "../../constants/navigation"
import { useAuth } from "../../context/authContext"
import { useServiceContext } from "../../context/serviceContext"
import { classNames, dev_log } from "../../utilities"
import ErrorDialog from "../Dialog/Error"
import SessionExpiredDialog from "../Dialog/SessionExpired"
import { ImpersonateUserBanner } from "../ImpersonateUserBanner"
import { Dialog, Menu, Transition } from "@headlessui/react"
import { BellIcon, MenuAlt2Icon, XIcon } from "@heroicons/react/outline"
import Link from "next/link"
import { useRouter } from "next/router"
import React, { Fragment, useState } from "react"
import { useCookies } from "react-cookie"

export interface Props {
  children: React.ReactNode
}

const Logo = () => (
  <Link href="/" className="cursor-pointer">
    <SiteLogo />
    <span className="text-white text-xl font-bold">Partner</span>
  </Link>
)

export function Layout({ children }: Props) {
  const router = useRouter()
  const { user, superUser, logout, sessionExpired } = useAuth()
  const { error } = useServiceContext()
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [cookies] = useCookies(["impersonatedUser"])

  const [impersonatedUserId, setImpersonatedUserId] = useState<string | null>(
    null
  )

  React.useEffect(() => {
    dev_log("superUser", superUser)
    const _impersonatedUserId = cookies.impersonatedUser
    if (_impersonatedUserId && adminUserIds.includes(superUser?.id)) {
      setImpersonatedUserId(_impersonatedUserId)
    }
  }, [cookies.impersonatedUser, superUser])

  return (
    <React.Fragment>
      <ErrorDialog
        open={error}
        setOpen={() => null}
        title="Oops, we've hit a snag!"
        body="Looks like we're experiencing a technical issue on our side. Please try reloading the page or contacting support."
      />
      <SessionExpiredDialog open={sessionExpired} setOpen={() => null} />
      {superUser?.id && (
        <ImpersonateUserBanner
          superUser={superUser}
          user={user}
          impersonatedUserId={impersonatedUserId}
        />
      )}
      {/* Mobile sidebar */}
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-40 md:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 flex z-40">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex-1 flex flex-col max-w-xs w-full bg-white">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>

                <div className="flex-1 h-0 overflow-y-auto">
                  <nav className="mt-5 px-2 space-y-1">
                    {navigation.map((item) => {
                      if (
                        item.name === "Admin" &&
                        !adminUserIds.includes(user?.id)
                      ) {
                      } else {
                        return (
                          <Link
                            key={item.name}
                            href={item.href}
                            className={classNames(
                              item.href.split("/")[1] ===
                                router.pathname.split("/")[1]
                                ? "text-primary"
                                : "text-alt hover:bg-gray-100 hover:text-alt",
                              "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                            )}
                          >
                            <item.icon
                              className={classNames(
                                item.href.split("/")[1] ===
                                  router.pathname.split("/")[1]
                                  ? "text-primary"
                                  : "text-gray-600 group-hover:text-alt",
                                "mr-4 flex-shrink-0 h-6 w-6"
                              )}
                              aria-hidden="true"
                            />
                            {item.name}
                          </Link>
                        )
                      }
                    })}
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
            {/* Force sidebar to shrink to fit close icon */}
            <div className="flex-shrink-0 w-14" />
          </div>
        </Dialog>
      </Transition.Root>

      {/* Desktop sidebar */}
      <div
        className={`
      hidden md:flex md:w-64 md:flex-col md:fixed 
      ${impersonatedUserId ? "md:bottom-0 md:top-10" : "md:inset-y-0"}
      `}
      >
        <div className="flex-1 flex flex-col min-h-0 bg-alt">
          <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
            <div className="flex items-center flex-shrink-0 px-4">
              <Logo />
            </div>
            <nav className="mt-5 flex-1 px-2 space-y-1">
              {navigation.map((item) => {
                if (item.name === "Admin" && !adminUserIds.includes(user?.id)) {
                }
                //
                else {
                  return (
                    <Link
                      key={item.name}
                      href={item.href}
                      className={classNames(
                        item.href.split("/")[1] ===
                          router.pathname.split("/")[1]
                          ? "text-white font-semibold"
                          : "text-gray-300  hover:text-white",
                        "group flex items-center px-2 py-2 text-sm rounded-md font-medium"
                      )}
                    >
                      <item.icon
                        className={classNames(
                          item.href.split("/")[1] ===
                            router.pathname.split("/")[1]
                            ? "text-primary"
                            : "text-gray-300 group-hover:text-white",
                          "mr-3 flex-shrink-0 h-6 w-6"
                        )}
                        aria-hidden="true"
                      />
                      {item.name}
                    </Link>
                  )
                }
              })}
            </nav>
          </div>
          <ProfileDisclosure
            name={user?.firstName || user?.email}
            navigation={userNavigation}
          />
        </div>
      </div>

      <div className="md:pl-64 flex flex-col flex-1">
        {/* Mobile top and sidebar */}
        <div className="sticky top-0 z-50 flex-shrink-0 flex h-16 bg-white shadow md:hidden">
          <button
            type="button"
            className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary md:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="flex-1 px-4 flex justify-between items-center">
            <Logo />
            <div className="ml-4 flex items-center md:ml-6">
              <button
                type="button"
                className="bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
              >
                <span className="sr-only">View notifications</span>
                <BellIcon className="h-6 w-6" aria-hidden="true" />
              </button>

              {/* Profile dropdown */}
              <Menu as="div" className="ml-3 relative">
                <div>
                  <Menu.Button className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
                    <span className="sr-only">Open user menu</span>
                    <div className="w-8 h-8 rounded-full bg-gray-100 overflow-hidden">
                      <ProfilePicture email={user?.email} />
                    </div>
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    {userNavigation.map((item) => (
                      <Menu.Item key={item.name}>
                        {({ active }) => (
                          <a
                            href={item.href}
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700"
                            )}
                          >
                            {item.name}
                          </a>
                        )}
                      </Menu.Item>
                    ))}
                    <Menu.Item>
                      {({ active }) => (
                        <a
                          role="button"
                          className={classNames(
                            active ? "bg-gray-100" : "",
                            "block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                          )}
                          onClick={logout}
                        >
                          Sign out
                        </a>
                      )}
                    </Menu.Item>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        </div>

        <main className="flex-1 pb-20">{children}</main>
      </div>
    </React.Fragment>
  )
}

import { Auth0User } from "../../context/authContext"
import { classNames } from "../../utilities"
import { ButtonLoader } from "../ButtonLoader"
import { submitPartnerSupportForm } from "./support.service"
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  XCircleIcon,
} from "@heroicons/react/outline"
import * as Sentry from "@sentry/nextjs"
import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"

interface FormData {
  firstName: string
  lastName: string
  email: string
  company: string
  phone: string
  message: string
}

export function ContactUs({ member }: { member: Auth0User }) {
  const [showSuccess, setShowSuccess] = useState(false)
  const [error, setError] = useState(false)

  const {
    register,
    formState: { errors, isSubmitting },
    handleSubmit,
    reset,
  } = useForm<FormData>()

  const onSubmit = async ({ phone, message }: FormData) => {
    try {
      setShowSuccess(false)
      setError(false)

      const crmInsert = await submitPartnerSupportForm(
        member.firstName || "",
        member.lastName || "",
        member.businessName || "",
        member.email || "",
        phone,
        message
      )
      if (crmInsert.Error) {
        throw new Error(crmInsert.Error)
      }

      setShowSuccess(true)
    } catch (err) {
      const _error = err as Error
      setError(true)
      Sentry.captureException(_error)
      console.log(_error)
    }
  }

  useEffect(() => {
    reset()
  }, [showSuccess, reset])

  return (
    <div className="mt-10">
      <h3 className="text-lg font-medium text-alt">Send us a message</h3>
      {showSuccess && (
        <div className="rounded-md bg-green-50 p-4 mt-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <CheckCircleIcon
                className="h-5 w-5 text-green-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <p className="text-sm font-medium text-green-800">
                Thank you for contacting us. We will reply to your email as soon
                as possible.
              </p>
            </div>
          </div>
        </div>
      )}

      {error && (
        <div className="rounded-md bg-red-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <XCircleIcon
                className="h-5 w-5 text-red-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <p className="text-sm font-medium text-red-800">
                There was an error with your submission. Please try again.
              </p>
            </div>
          </div>
        </div>
      )}

      <form
        className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="sm:col-span-2">
          <div className="mt-1">
            {/* <input
              id="phone"
              name="phone"
              placeholder="Phone number"
              className={classNames(
                errors.message
                  ? "focus:ring-red-500 focus:border-red-500"
                  : "focus:ring-primary focus:border-primary",
                "w-full shadow-sm  sm:text-sm border-gray-300 rounded-md"
              )}
              {...register("phone", { required: true })}
            /> */}
            <input
              id="phone"
              name="phone"
              placeholder="Phone number"
              type="text"
              className="mt-1 focus:ring-primary focus:border-primary block w-full max-w-sm shadow-sm sm:text-sm border-gray-300 rounded-md "
              {...register("phone", { required: true })}
            />
            {errors.phone && (
              <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <ExclamationCircleIcon
                  className="h-5 w-5 text-red-500"
                  aria-hidden="true"
                />
              </div>
            )}
          </div>
          {errors.phone && (
            <p className="mt-2 text-sm text-red-600">
              Phone number is required
            </p>
          )}
        </div>
        <div className="sm:col-span-2">
          <div className="mt-1">
            <textarea
              id="message"
              name="message"
              rows={4}
              placeholder="Your message"
              className={classNames(
                errors.message
                  ? "focus:ring-red-500 focus:border-red-500"
                  : "focus:ring-primary focus:border-primary",
                "w-full shadow-sm  sm:text-sm border-gray-300 rounded-md"
              )}
              {...register("message", { required: true })}
            />
            {errors.message && (
              <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <ExclamationCircleIcon
                  className="h-5 w-5 text-red-500"
                  aria-hidden="true"
                />
              </div>
            )}
          </div>
          {errors.message && (
            <p className="mt-2 text-sm text-red-600">Message is required</p>
          )}
        </div>
        <div className="sm:col-span-2 sm:flex sm:justify-end">
          <button
            type="submit"
            disabled={
              isSubmitting || Boolean(errors.message) || Boolean(errors.phone)
            }
            className="btn-primary disabled:opacity-50 disabled:bg-gray-400 disabled:shadow-none disabled:border-none"
          >
            <ButtonLoader loading={isSubmitting} loadingText="Sending">
              Send
            </ButtonLoader>
          </button>
        </div>
      </form>
    </div>
  )
}

import { useEffect, useState } from 'react';

import { classNames } from '../../utilities';

const colors = [
  'bg-red-100',
  'bg-teal-100',
  'bg-slate-100',
  'bg-blue-100',
  'bg-blue-100',
  'bg-green-100'
];
const randomColor = colors[Math.floor(Math.random() * colors.length)];

export function ProfilePicture({ email }: { email: string }) {
  const [profileColor, setProfileColor] = useState('bg-gray-300');

  useEffect(() => {
    const storageColor = localStorage.getItem('profileColor');
    if (storageColor) {
      setProfileColor(storageColor);
    } else {
      localStorage.setItem('profileColor', randomColor);
      setProfileColor(randomColor);
    }
  }, []);

  return (
    <div
      className={classNames(
        profileColor,
        'rounded-full h-8  w-8 text-alt flex items-center justify-center text-lg uppercase font-bold'
      )}
    >
      {email?.charAt(0)}
    </div>
  );
}

import { classNames } from "../../utilities"
import {
  ArrowRightIcon,
  BookmarkIcon,
  DocumentAddIcon,
  DocumentTextIcon,
  ShoppingCartIcon,
  SupportIcon,
} from "@heroicons/react/outline"

const actions = [
  {
    title: "File a claim",
    href: "/claims",
    icon: DocumentAddIcon,
    iconForeground: "text-rose-700",
    iconBackground: "bg-rose-50",
  },
  {
    title: "Shop new coverage",
    href: "/shop-coverage",
    icon: ShoppingCartIcon,
    iconForeground: "text-purple-700",
    iconBackground: "bg-purple-50",
  },
  {
    title: "View policies",
    href: "/policies",
    icon: DocumentTextIcon,
    iconForeground: "text-sky-700",
    iconBackground: "bg-sky-50",
  },
  {
    title: "Get Certificate of Insurance",
    href: "/certificates",
    icon: BookmarkIcon,
    iconForeground: "text-cyan-700",
    iconBackground: "bg-cyan-50",
  },
  {
    title: "Contact support",
    href: "/support",
    icon: SupportIcon,
    iconForeground: "text-teal-700",
    iconBackground: "bg-teal-50",
  },
]

export function ActionsList() {
  return (
    <div className="sm:grid sm:grid-cols-2 gap-4 mt-2">
      {actions.map((action) => (
        <div
          key={action.title}
          className="my-2 sm:my-0 rounded-lg shadow relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-primary flex items-center"
        >
          <div className="flex items-center space-x-4">
            <span
              className={classNames(
                action.iconBackground,
                action.iconForeground,
                "rounded-lg inline-flex p-3 ring-4 ring-white"
              )}
            >
              <action.icon className="h-6 w-6" aria-hidden="true" />
            </span>
            <h3 className="text-lg font-medium">
              <a href={action.href} className="focus:outline-none">
                <span className="absolute inset-0" aria-hidden="true" />
                {action.title}
              </a>
            </h3>
          </div>
          <ArrowRightIcon className="h-6 w-6 text-gray-300 group-hover:text-gray-400 ml-auto" />
        </div>
      ))}
    </div>
  )
}

import { QuestionMarkCircleIcon } from '@heroicons/react/solid';
import { Content, Root, Trigger } from '@radix-ui/react-tooltip';

export interface Props {
  content: string;
  placement?: 'top' | 'right' | 'bottom' | 'left';
  icon?: JSX.Element;
}

export function Tooltip({ content, placement = 'top', icon }: Props) {
  return (
    <Root delayDuration={0}>
      <Trigger>
        {icon ? (
          icon
        ) : (
          <QuestionMarkCircleIcon className="h-5 w-5 text-gray-400" />
        )}
      </Trigger>
      <Content
        side={placement}
        sideOffset={4}
        className="bg-white p-4 shadow rounded-md text-gray-500 ring-black ring-opacity-5 ring-1 max-w-xs text-sm"
      >
        {content}
      </Content>
    </Root>
  );
}

import Link from "next/link"
import Router from "next/router"
import React from "react"

export function Error() {
  return (
    <div className="-mx-4 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg p-8 bg-white">
      <div className="text-center max-w-md mx-auto">
        <h2 className="text-lg font-medium text-alt">Something went wrong</h2>
        <p className="mt-2 text-base text-gray-500">
          Please try refreshing the page. If you continue to have trouble please{" "}
          <Link
            href="/support"
            className="text-primary hover:text-primaryHover"
          >
            contact us
          </Link>
          .
        </p>
        <div className="mt-4 text-center">
          <button
            onClick={() => Router.reload()}
            type="button"
            className="btn-secondary"
          >
            Refresh
          </button>
        </div>
      </div>
    </div>
  )
}

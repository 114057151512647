/* eslint-disable @typescript-eslint/no-unused-vars */
import { Error, Success } from "."
import { ButtonLoader } from ".."
import { useAuth } from "../../context/authContext"
import { classNames } from "../../utilities"
import { EyeIcon, EyeOffIcon } from "@heroicons/react/outline"
import * as Sentry from "@sentry/nextjs"
import React, { useState } from "react"
import { useForm } from "react-hook-form"

interface FormData {
  password: string
  confirmPassword: string
}

export function PasswordTab() {
  const [pageLoading, setPageLoading] = useState(true)
  const { user, resetPassword } = useAuth()

  const [loading, setLoading] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [passwordVisible, setPasswordVisible] = useState(false)
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false)
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>()

  const onSubmit = async ({ password }: FormData) => {
    setLoading(true)
    setErrorMessage("")

    // TODO: Update password via Auth0 Management API
    // For now, send the reset password email
    try {
      await resetPassword(user.email)
      setShowSuccess(true)
    } catch (error) {
      setErrorMessage(error.message)
      Sentry.captureException(error.message)
    }

    setLoading(false)

    // if (error) {
    //   setErrorMessage(error.message)
    //   Sentry.captureException(error.message)
    //   return
    // }

    // if (data.user) {
    //   setShowSuccess(true)
    // }
  }
  return (
    <div className="bg-white shadow px-4 sm:rounded-lg p-6">
      <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
        <div>
          <h2 className="text-xl font-medium text-alt">Change your password</h2>
        </div>
        <div className="md:col-span-2">
          {errorMessage && <Error message={errorMessage} />}
          {showSuccess && <Success message="Email sent!" />}
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="col-span-4 flex mt-6">
              <button type="submit" className="btn-primary w-full sm:w-48">
                <ButtonLoader loading={loading} loadingText="Sending">
                  Send me a reset link
                </ButtonLoader>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
  // return (
  //   <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
  //     <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
  //       <div>
  //         <h2 className="text-xl font-medium text-alt">Change your password</h2>
  //       </div>
  //       <div className="mt-5 md:mt-0 md:col-span-2">
  //         {errorMessage && <Error message={errorMessage} />}
  //         {showSuccess && <Success />}
  //         <form onSubmit={handleSubmit(onSubmit)}>
  //           <div>
  //             <label
  //               htmlFor="password"
  //               className="block text-sm font-medium text-gray-700"
  //             >
  //               New password
  //             </label>
  //             <div className="relative rounded-md shadow-sm">
  //               <input
  //                 type={passwordVisible ? "text" : "password"}
  //                 name="password"
  //                 id="password"
  //                 autoComplete="off"
  //                 className={classNames(
  //                   errors.password
  //                     ? "focus:ring-red-500 focus:border-red-500"
  //                     : "focus:ring-primary focus:border-primary",
  //                   "mt-1 block w-full sm:text-sm border-gray-300 rounded-md"
  //                 )}
  //                 {...register("password", { required: true })}
  //               />
  //               <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
  //                 <button onClick={() => setPasswordVisible(!passwordVisible)}>
  //                   {passwordVisible ? (
  //                     <EyeOffIcon
  //                       className="h-5 w-5 text-gray-400"
  //                       aria-hidden="true"
  //                     />
  //                   ) : (
  //                     <EyeIcon
  //                       className="h-5 w-5 text-gray-400"
  //                       aria-hidden="true"
  //                     />
  //                   )}
  //                 </button>
  //               </div>
  //             </div>
  //             {errors.password && (
  //               <p className="mt-2 text-sm text-red-600" id="email-error">
  //                 Password is required
  //               </p>
  //             )}
  //           </div>

  //           <div className="mt-6">
  //             <label
  //               htmlFor="confirmPassword"
  //               className="block text-sm font-medium text-gray-700"
  //             >
  //               Confirm new password
  //             </label>
  //             <div className="relative rounded-md shadow-sm">
  //               <input
  //                 type={confirmPasswordVisible ? "text" : "password"}
  //                 name="confirmPassword"
  //                 id="confirmPassword"
  //                 autoComplete="off"
  //                 className={classNames(
  //                   errors.confirmPassword
  //                     ? "focus:ring-red-500 focus:border-red-500"
  //                     : "focus:ring-primary focus:border-primary",
  //                   "mt-1 block w-full sm:text-sm border-gray-300 rounded-md"
  //                 )}
  //                 {...register("confirmPassword", {
  //                   required: true,
  //                 })}
  //               />
  //               <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
  //                 <button
  //                   onClick={() =>
  //                     setConfirmPasswordVisible(!confirmPasswordVisible)
  //                   }
  //                 >
  //                   {confirmPasswordVisible ? (
  //                     <EyeOffIcon
  //                       className="h-5 w-5 text-gray-400"
  //                       aria-hidden="true"
  //                     />
  //                   ) : (
  //                     <EyeIcon
  //                       className="h-5 w-5 text-gray-400"
  //                       aria-hidden="true"
  //                     />
  //                   )}
  //                 </button>
  //               </div>
  //             </div>
  //             {errors.confirmPassword && (
  //               <p className="mt-2 text-sm text-red-600" id="email-error">
  //                 Passwords must match
  //               </p>
  //             )}
  //           </div>

  //           <div className="col-span-4 flex justify-end mt-6">
  //             <button type="submit" className="btn-primary">
  //               <ButtonLoader loading={loading} loadingText="Saving">
  //                 Save
  //               </ButtonLoader>
  //             </button>
  //           </div>
  //         </form>
  //       </div>
  //     </div>
  //   </div>
  // )
}
